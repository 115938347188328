import * as React from 'react';
import { navStyle } from './Nav_style';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, MenuItem, Button } from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { showScheduleDemoModal } from '../utils';
const pages = ['Home', 'Product & Services', 'Price', 'AboutUs'];

export default function ResponsiveAppBar() {
    const nav = navStyle();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [activeClass, setActiveClass] = useState<string>('scroll-shadow-none');
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            let activeClass = 'scroll-shadow';
            if (window.scrollY === 0) {
                activeClass = 'scroll-shadow-none';
            }
            setActiveClass(activeClass);
        });
    }, []);

    return (
        <AppBar className={activeClass} position="sticky" color="default" enableColorOnDark>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <IconButton sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, borderRadius: '0px' }}
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="primary"
                    >
                        <span className="memss_c-logo"></span>
                    </IconButton>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="primary"
                        >
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">
                                        <Link to={`/${page}`} style={{ textDecoration: "none", color: "black" }} >
                                            {page}
                                        </Link>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <IconButton sx={{ display: { xs: 'flex', md: 'none' }, mr: 2, borderRadius: '0px' }}
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit">
                        <span className="memss_c-logo"></span>
                    </IconButton>
                    <Box sx={{ flexGrow: 3, display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center' }} >
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, mx: 2, color: 'white', display: 'block' }}
                            >
                                <Link style={{ textDecoration: "none", color: "black", fontWeight: 'bold' }} to={`/${page}`}>
                                    {page}
                                </Link>
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Button onClick={() => {
                            showScheduleDemoModal(true);
                        }} variant="contained" color='primary'>Schedule Demo</Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
