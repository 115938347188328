
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReCAPTCHA from "react-google-recaptcha";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { _sendEmail } from '../services/api';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';


const initialUserData = {
  name: "",
  email: "",
  mobile: "",
  company: "",
  message: ""
};


const ScheduleDemo = () => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const [userData, setUserData] = React.useState(initialUserData);
  const [errors, setErrors] = React.useState(initialUserData);
  const [captchaKey, setCaptchaKey] = React.useState<string>('');
  const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false);
  let recaptchaInstance;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [buttonTxt, setButtonTxt] = React.useState<string>('SUBMIT');
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };
  const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    color: green[700],
    textAlign: 'center',
    fontWeight: 'bold'
  }));

  const resetForm = () => {
    setErrors(initialUserData);
    setUserData(initialUserData);
    setCaptchaKey('');
    setFormSubmitted(false);
  }

  const resetToDefaults = () => {
    setSuccess(false);
    setLoading(false);
    setButtonTxt('SUBMIT');
  }

  const updateUserDataHandler = React.useCallback(
    (type) => (event) => {
      setErrorsHandler(type, event.target.value);
      setUserData({ ...userData, [type]: event.target.value });
    },
    [userData]
  );

  const handleClose = () => {
    setOpen(false);
    resetForm();
    resetToDefaults();
  };

  const resetRecaptcha = () => {
    recaptchaInstance?.reset();  
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    resetRecaptcha();
    window.addEventListener('storage', (e) => {
        const getValue = localStorage.getItem('showRequestDemo');
        if (open !== Boolean(getValue)) {
            setOpen(Boolean(getValue));
        }
        resetRecaptcha();
    });
  }, []);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const formHandler = async () => {
    if (!checkErrors()) {
      setButtonTxt('SUBMITTING');
      setSuccess(false);
      setLoading(true);
      const params = {
        Name: userData.name,
        Email: userData.email,
        Message: userData.message,
        Contact: userData.mobile,
        Company: userData.company,
        Type: 'DEMO_REQUEST',
        RecaptchaToken: captchaKey
      };
      const result = await _sendEmail(params);
      if (result.status === 200) {
        setSuccess(true);
        setLoading(false);
        setButtonTxt('SUBMITTED');
      } else {
        setSuccess(false);
        setLoading(false);
        setButtonTxt('SUBMIT');
      }
    }
  }
  
  const setErrorsHandler = (key, value) => {
      if (key === 'name') {
        if (/^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i.test(value) ) {
          setErrors({ ...errors, [key]: '' });
        } else {
          setErrors({ ...errors, [key]: 'Invalid Name' });
        }
      }
      if (key === 'email') {
        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ) {
          setErrors({ ...errors, [key]: '' });
        } else {
          setErrors({ ...errors, [key]: 'Invalid Email Address' });
        }
      }
      if (key === 'mobile') {
        if (/^(\+\d{1,3}[- ]?)?\d{8,15}$/.test(value) ) {
          setErrors({ ...errors, [key]: '' });
        } else {
          setErrors({ ...errors, [key]: 'Invalid Mobile Number' });
        }
      }
      if (key === 'company') {
        if (/^(?=.{1,100}$)[a-z0-9]+(?:['_.\s][a-z]+)*$/i.test(value) ) {
          setErrors({ ...errors, [key]: '' });
        } else {
          setErrors({ ...errors, [key]: 'Invalid Company Name' });
        }
      }
  }

  const checkErrors = () => {
    let errorExist = false;
    let errorText = `*Fill all the missing fields.`;
    for (let item in userData) {
      if (item !== 'message' && !userData[item]) {
        errorExist = true;
        errorText += ` ${item}, `;
      }
    }
    if (!captchaKey) {
      errorText += ` Captcha.`
    }
    return errorExist ? errorText : null;
  }

  return (
    <div>
            <Dialog
                open={open}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                disableEscapeKeyDown={false}
            >
                
                    <DialogTitle id="scroll-dialog-title">SCHEDULE A DEMO</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        {success && <Grid container justifyContent={'center'} display={'flex'} spacing={3}>
                          <Grid item xs={12} md={12} pb={3}>
                            <Div>
                              {`Thank you for submitting your request for a demo. You can expect to hear from the team within the next 24-48 hours.`}
                            </Div>
                          </Grid>
                        </Grid>}
                        {!success && <Grid container justifyContent={'center'} display={'flex'} spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors?.name)}
                                        helperText={errors?.name}
                                        required
                                        id="name"
                                        onChange={updateUserDataHandler("name")}
                                        label="Full Name"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors?.email)}
                                        helperText={errors?.email}
                                        required
                                        id="email"
                                        label="Email"
                                        onChange={updateUserDataHandler("email")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors?.mobile)}
                                        helperText={errors?.mobile}
                                        required
                                        id="contact"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        onChange={updateUserDataHandler("mobile")}
                                        label="Contact Number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors?.company)}
                                        helperText={errors?.company}
                                        required
                                        id="company"
                                        onChange={updateUserDataHandler("company")}
                                        label="Company Name"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        error={Boolean(errors?.message)}
                                        helperText={errors?.message}
                                        multiline
                                        rows={2}
                                        onChange={updateUserDataHandler("message")}
                                        id="message"
                                        label="Message"
                                    />
                                </Grid>
                                {checkErrors() && formSubmitted && <Grid item xs={12} md={12}>
                                  <Alert severity="error">{checkErrors()}</Alert>
                                </Grid>}
                                {!success && !loading && !checkErrors() && formSubmitted && <Grid item xs={12} md={12}>
                                  <Alert severity="error">{`Oops ! Unable to send your request please try again.`}</Alert>
                                </Grid>}
                                <Grid item xs={12} md={12}>
                                    <ReCAPTCHA
                                        ref={e => recaptchaInstance = e}
                                        onChange={(e) => {
                                          setCaptchaKey(e);
                                        }}
                                        onExpired={(e) => {
                                          setCaptchaKey('');
                                        }}
                                        onErrored={(e) => {
                                          setCaptchaKey('');
                                        }}
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                    />
                                </Grid>
                        </Grid>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>CLOSE</Button>
                        {!success && <Button
                          variant="contained"
                          sx={buttonSx}
                          disabled={loading || success}
                          onClick={() => {
                            setFormSubmitted(true);
                            formHandler()
                          }}
                        >
                          {buttonTxt}
                        </Button>}
                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                    </DialogActions>
            </Dialog>
    </div>
  );
}

export default ScheduleDemo;
