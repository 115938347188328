import React  from "react";

const PageNotFound = () => {
    return (
        <div>
            PageNotFound page
        </div>
    )
}

export default PageNotFound;