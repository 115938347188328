import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  Button,
  CardContent,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  tableCellClasses,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import React from "react";
import { commonStyle } from "./common_style";
import { homeStyle } from "./Home_style";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Price = () => {
  const home = homeStyle();
  const classes = commonStyle();
  return (
    <Box>
      <Box >
        <Box sx={{ background: "#F5F8FF", borderRadius: "0px 0px 50% 50%" }}>
          <Container sx={{ padding: "50px 0px 1% 0px" }}>
            <Typography
              fontWeight={900}
              textAlign="center"
              color="primary"
              fontSize={25}
              mb={3}
            >
              Pricing
            </Typography>
            <Typography textAlign="center" color="dark" fontSize={18} display={"none"}>
              MEMSS offers a asset management solution that ensures
              cost-effective utilization of your assets. With flexible pricing
              plans tailored to your needs, you can maximize your ROI and
              streamline your operations.
            </Typography>
          </Container>
        </Box>
        <Container>
        <Box my={3}> 
          
          <Typography variant="subtitle1" mb={3}>At MEMSS, we understand that each business is unique and operates within its industry with specific demands and challenges. That's why our solution is highly customizable and designed to cater to the diverse needs of companies across multiple sectors, ensuring unrivalled flexibility to meet your exact requirements.</Typography>

          <Typography variant="subtitle1" mb={3}>We take great pride in our ability to deliver highly customizable B2B solutions that we can seamlessly tailor and align with your organization's specific business objectives. Whether you're in manufacturing, finance, or any other industry, our adaptable offering guarantees a perfect fit for your company.</Typography>

          <Typography variant="subtitle1" mb={3}>By choosing our comprehensive B2B solutions, you gain access to a range of meticulously crafted features and functionalities that cater to a wide array of business operations. We're committed to providing you with a fully personalized solution that empowers your business to thrive and stay ahead of the competition.</Typography>

          <Typography variant="subtitle1" mb={3}>To truly unlock the full potential and explore the possibilities of our expandable solution, we strongly encourage you to contact us today. Our dedicated team is eager to collaborate with you, taking the time to understand your needs and objectives deeply. From there, we will provide a detailed and customized quote tailored to your unique business requirements.</Typography>

          <Typography variant="subtitle1">Take advantage of the opportunity to take the next step towards driving success and growth for your business. Contact us now to harness the power of our versatile and scalable solution, and let us propel your business forward into a prosperous future.</Typography>
        </Box>
        </Container>
        <Box display={"none"}>
          <Container>
            <Grid
              container
              justifyContent={"center"}
              display={"flex"}
              spacing={3}
              pb={10}
            >
              <Grid item xs={12} md={3} height="100%">
                <Card className={classes.cst_block} color="primary">
                  <Icon
                    color="primary"
                    sx={{
                      fontWeight: "900",
                      padding: "40px 20px",
                      width: "88%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        width: "100%",
                      }}
                    >
                      <Typography fontWeight={"bold"} fontSize={"24px"}>
                        Scalable
                      </Typography>
                      <List>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#5D5FEF", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            color="secondary"
                            primary="Incl. 200 Assets"
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "Primary",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#5D5FEF", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary=" 400 Work Orders / Month
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "Primary",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#5D5FEF", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Azure Cloud Hosting
                                                   "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "Primary",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#5D5FEF", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary=" 2 GB Storage
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "Primary",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#5D5FEF", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="20 Users"
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "Primary",
                            }}
                          />
                        </ListItem>
                      </List>
                      <Button variant="outlined" sx={{ minWidth: "150px" }}>
                        Try Free
                      </Button>
                    </Box>
                  </Icon>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card className={classes.cst_block} color="error">
                  <Icon
                    color="error"
                    sx={{
                      fontWeight: "900",
                      padding: "40px 20px",
                      width: "86%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <div className="ribbon ribbon-top-right">
                      <span>Professional</span>
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        width: "100%",
                      }}
                    >
                      <Typography fontWeight={"bold"} fontSize={"24px"}>
                        Professional
                      </Typography>
                      <List>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#F57059", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            color="secondary"
                            primary="Incl. 450 Assets
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "error",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#F57059", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary=" 1000 Work Orders / Month
                                                    
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "error",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#F57059", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Azure Cloud Hosting
                                                    
                                                   "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "error",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#F57059", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="5 GB Storage
                                                    
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "error",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#F57059", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="100 Users"
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "error",
                            }}
                          />
                        </ListItem>
                      </List>
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ minWidth: "150px" }}
                      >
                        Try Free
                      </Button>
                    </Box>
                  </Icon>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card className={classes.cst_block} color="warning">
                  <Icon
                    color="warning"
                    sx={{
                      fontWeight: "900",
                      padding: "40px 20px",
                      width: "90%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        width: "100%",
                      }}
                    >
                      <Typography fontWeight={"bold"} fontSize={"24px"}>
                        Business
                      </Typography>
                      <List>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#FEBC1F", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary=" Incl. 800 Assets
                                                    
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "warning",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#FEBC1F", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="2500 Work Orders / Month
                                                    
                                                   "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "warning",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#FEBC1F", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary=" Azure Cloud Hosting
                                                    
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "warning",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#FEBC1F", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="15 GB Storage
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "warning",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#FEBC1F", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            color="warning"
                            primary="Unlimited Users"
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "warning",
                            }}
                          />
                        </ListItem>
                      </List>
                      <Button
                        variant="outlined"
                        color="warning"
                        sx={{ minWidth: "150px" }}
                      >
                        Try Free
                      </Button>
                    </Box>
                  </Icon>
                </Card>
              </Grid>

              <Grid item xs={12} md={3}>
                <Card className={classes.cst_block} color="Success">
                  <Icon
                    color="primary"
                    sx={{
                      fontWeight: "900",
                      padding: "40px 20px",
                      width: "85%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "start",
                        width: "100%",
                      }}
                    >
                      <Typography
                        fontWeight={"bold"}
                        fontSize={"24px"}
                        color="#44997C"
                      >
                        Dedicated
                      </Typography>
                      <List>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#44997C", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            color="secondary"
                            primary="Unlimited Assets
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "#44997C",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#44997C", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Unlimited Work Orders
                                                   
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "#44997C",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#44997C", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary=" Azure Cloud Hosting
                                                    
                                                   "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "#44997C",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#44997C", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Unlimited Storage
                                                    
                                                    "
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "#44997C",
                            }}
                          />
                        </ListItem>
                        <ListItem component="li" disablePadding>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <DoneIcon
                              sx={{ color: "#44997C", fontSize: "18px" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Unlimited Users"
                            primaryTypographyProps={{
                              fontSize: 16,
                              fontWeight: "600",
                              letterSpacing: 0,
                              color: "#44997C",
                            }}
                          />
                        </ListItem>
                      </List>
                      <Button
                        variant="outlined"
                        color="success"
                        sx={{ minWidth: "150px" }}
                      >
                        Try Free
                      </Button>
                    </Box>
                  </Icon>
                </Card>
              </Grid>
            </Grid>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "primary",
                      }}
                    >
                      Overview{" "}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "primary",
                      }}
                    >
                      Economy{" "}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "primary",
                      }}
                    >
                      Professional{" "}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "primary",
                      }}
                    >
                      Business{" "}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "primary",
                      }}
                    >
                      Dedicated{" "}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Asset Management
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Trial Assets/indemnity
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Testing tool calibration
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Manufacturers
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Asset Disposable
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Work Orders
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Mass Cancellation
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Mass Allocation
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Mass Movement
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Manager Parts Apporval
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Admin Parts PO
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Signature
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      End user careate WD
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      End User Create Communication
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Financial Owner - Cost Approval
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Engineers Dashboard
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Engineers Progress Record
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Parts Delivery Tracking
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Wo Invoice
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Location Month PM Job Schedule
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Equipment Library
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      End User Reservations
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      End User - Loans Tracking
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Training Compliance
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      End User - Self Training Record
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      End User - Accept/ Reject Training Schedule
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      End User - Track Compliance Training
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      End User - Compliance Training Alerts
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Contract Management
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      User Management
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Company(Vendor) Management
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Role Based Dashboard
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Customize Workflow
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary">
                        {" "}
                        <DoneIcon />
                      </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      (Limited) (Limited) (Limited)
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary"> </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Integration to In-house Systems(additional Cost)
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary"> </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Extensive Customization of Reference Data
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary"> </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "18px", color: "#000" }}
                    >
                      Bespoke Development(additional Cost)
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "16px", color: "primary" }}
                    >
                      <Icon color="primary"> </Icon>{" "}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"></Icon>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Icon color="primary"> </Icon>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
        <Box display={"none"}>
          <Container sx={{ padding: "50px 0px 5% 0px" }}>
            <Typography
              fontWeight={900}
              textAlign="center"
              color="primary"
              fontSize={35}
              mb={3}
            >
              FAQ
            </Typography>

            <Accordion elevation={3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ paddingY: " 15px" }}
              >
                <Typography color="#3734A9" fontSize="20px" fontWeight="600">
                  Do you offer a free trial?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes! We offer a free 30 day trial of our standard package.
                  During the trial you’ll get access to all features and help
                  from our amazing customer service team. After the trial,
                  you’ll be invited to select your desired package. *If you
                  downgrade, you will permanently loose any data associated with
                  features from your old subscription package that are not
                  available on your new package
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ paddingY: " 15px" }}
              >
                <Typography color="#3734A9" fontSize="20px" fontWeight="600">
                  Can I re-open my old account?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Yes, u can open ur account.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                sx={{ paddingY: " 15px" }}
              >
                <Typography color="#3734A9" fontSize="20px" fontWeight="600">
                  Can I change my plan?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography fontSize={16}>
                  Yes, u can change anytime.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Price;
