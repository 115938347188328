export const _sendEmail = async (reqBody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "Name": reqBody.Name,
            "Contact": reqBody.Contact,
            "Company": reqBody.Company,
            "Email": reqBody.Email,
            "Message": reqBody.Message || '',
            "Type": reqBody.Type,
            "RecaptchaToken": reqBody.RecaptchaToken
        })
    };
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/Email/SendEmail`, requestOptions);
        const data = await response.json();
        return { status: response.status, data: data };
    } catch (error) {
        return {
            hasError: true,
            error,
        };
    }
}