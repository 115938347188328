import { commonStyle } from './common_style';
import * as React from 'react';
import { homeStyle } from './Home_style';
import { Box, Typography, Container, Icon, Grid, Card, Button, CardContent, Paper, Tab, Tabs, List, ListItemButton, ListItem, ListItemIcon, ListItemText, ImageList, ImageListItem } from '@mui/material';
import InboxIcon from '@mui/icons-material/ArrowRightAlt';
import arror from '@mui/icons-material/ArrowForwardIos';
import imagePath from './../assets/images/dashboard.png';
import assets from './../assets/images/assets.png';
import work from './../assets/images/work.png';
import equipement from './../assets/images/equipement.png';
import parts from './../assets/images/parts.png';
import training from './../assets/images/training.png';
import contracts from './../assets/images/contracts.png';
import reports from './../assets/images/reports.png';
import manu from './../assets/images/manu.png';
import finance from './../assets/images/assets.png';
import supplier from './../assets/images/assets.png';
import inventory from './../assets/images/assets.png';
import product from './../assets/images/assets.png';
import invoice from './../assets/images/assets.png';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import one from './../assets/images/one.png';
import two from './../assets/images/two.png';
import three from './../assets/images/three.png';
import four from './../assets/images/four.png';
import five from './../assets/images/five.png';
import ProductManagement from './../assets/images/ProductManagement.jpg';
import InvoiceManagement from './../assets/images/InvoiceManagement.jpg';
import AssetManagement from './../assets/images/AssetManagement.jpg';
import notificationMaintenance from './../assets/images/notificationMaintenance.jpg';
import supplymanagement from './../assets/images/supplymanagement.jpg';
import InventoryManagement from './../assets/images/InventoryManagement.jpg';
import Financeintegrated from './../assets/images/Financeintegrated.jpg';
import Purchaseorder from './../assets/images/Purchaseorder.jpg';
import Salesorder from './../assets/images/Salesorder.jpg';
import Quotationorder from './../assets/images/Quotationorder.jpg';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Solutions(props: any) {
    // style instances
    const classes = commonStyle();
    const home = homeStyle();
    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [svalue, setsValue] = React.useState(0);

    const handleChanges = (_event: React.SyntheticEvent, newValue: number) => {
        setsValue(newValue);
    };

    return (
        <Box>
            <Box sx={{ position: 'relative', display: { xs: 'none', md: 'block' } }}>
                <Icon sx={{ position: 'absolute', display: 'flex', bottom: '100px', left: 0, fontSize: '20vmin' }} color='primary' ><span className="memss_c-Vector" style={{ color: 'rgb(163 163 249)', zIndex: 3, }}></span></Icon>
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{ height: { xs: '100%', md: '600px' }, overflow: 'hidden', }} display='flex' alignItems='center' justifyContent='center' >
                        <Box sx={{ border: { xs: 'none', md: '30px solid #EFEFFF' }, position: { xs: 'relative', md: 'absolute' }, top: { xs: '0px', md: '-85%' }, borderRadius: { xs: '2px', md: '50%' }, padding: { xs: '10px', md: '40px' } }}>
                            <Box display='flex' alignItems='end' justifyContent='center' sx={{ background: 'linear-gradient(359.4deg, #3734A9 5.87%, rgba(55, 52, 169, 0) 134.04%)', borderRadius: { xs: '2px', md: '50%' }, height: { xs: '100%', md: '881px' }, width: { xs: '100%', md: '881px' }, }}>
                                <Typography variant='h1' color='white' textTransform='uppercase' fontWeight='900' textAlign='center' sx={{ marginX: { xs: '10px', md: '50px' }, paddingBottom: { xs: '0px', md: '150px' }, fontSize: { xs: '20px', md: '44px', lineHeight: { xs: '30px', md: '80px' } } }}>
                                    MEMSS is the <Typography className='animation-text' variant='h1' display='inline' textTransform='capitalize' fontWeight='900' textAlign='center' sx={{ fontSize: { xs: '20px', md: '48px' } }}>Optimal solution</Typography>  for asset and medical equipment maintenance challenges.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box marginBottom={6}>
                <Container>
                    <Typography variant='h1' textAlign='center' fontWeight='bold' fontSize='48px' mb={3}>Our <Typography variant='h1' textAlign='center' display='inline' color='primary' fontWeight='900' fontSize='48px'>Solution</Typography></Typography>
                    <Grid container justifyContent={'center'} display={'flex'} spacing={5} >
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cst_block} color='primary'>
                                <Button fullWidth color='primary' sx={{ fontWeight: '900', padding: '20px' }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                        <Icon className="memss_c-Vector-3" sx={{ margin: '30px 0px 30px 0px', fontSize: '7rem' }} color='primary'></Icon>
                                        <CardContent sx={{ p: '0px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                                Scalable
                                            </Typography>

                                        </CardContent>
                                    </Box>
                                </Button>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cst_block} color='warning'>
                                <Button fullWidth color='warning' sx={{ fontWeight: '900', padding: '20px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                        <Icon className="memss_c-integration-1" sx={{ margin: '30px 0px 30px 0px', fontSize: '7rem' }} color='warning'></Icon>
                                        <CardContent sx={{ p: '0px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                                Integration
                                            </Typography>

                                        </CardContent>
                                    </Box>
                                </Button>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cst_block} color='error'>
                                <Button fullWidth color='error' sx={{ fontWeight: '900', padding: '20px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                        <Icon className="memss_c-authentic-1" sx={{ margin: '30px 0px 30px 0px', fontSize: '7rem' }} color='error'></Icon>
                                        <CardContent sx={{ p: '0px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                                Complaint
                                            </Typography>

                                        </CardContent>
                                    </Box>
                                </Button>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box mb={5}>
                <Typography variant='h1' textAlign='center' fontWeight='900' fontSize='48px' mb={6}>Key  <Typography variant='h1' textAlign='center' display='inline' color='primary' fontWeight='900' fontSize='48px'>Features</Typography></Typography>
                <Box className={home.service_4} position='relative'>
                    <Box className={home.overlay}></Box>
                    <Grid container justifyContent={'center'} display={'flex'} zIndex={2} position='relative' py={14}>
                        <Grid item xs={12} md={5}>

                        </Grid>
                        <Grid item container xs={12} md={6} display='flex' alignItems='center' justifyContent='center' borderRadius='20px' flexDirection='column' spacing={3} className='cst_blk'>
                            <Grid item width={'80%'}>
                                <div className='key_f'>
                                    <div className='info_blk'>
                                        <h1 aria-details="key_h">Zero Client Installation  </h1>
                                        <p aria-label='key_p'>MEMSS architecture is expandable and flexible to integrate new-to-market solutions i.e., Artificial Intelligence, etc.</p>
                                        <div className='line_main'><span className='line_blk'><ArrowRightAlt className='line-icon' sx={{ color: '#3734A9' }} /></span></div>
                                    </div>
                                    <div className='img_blk'>
                                        <img src={one} height='120' />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width={'80%'}>
                                <div className='key_f'>
                                    <div className='info_blk'>
                                        <h1 aria-details="key_h">Expandable Architecture </h1>
                                        <p aria-label='key_p'>MEMSS architecture is expandable and flexible to integrate new-to-market solutions i.e., Artificial Intelligence, etc.</p>
                                        <div className='line_main'><span className='line_blk'><ArrowRightAlt className='line-icon' sx={{ color: '#3734A9' }} /></span></div>
                                    </div>
                                    <div className='img_blk'>
                                        <img src={two} height='120' />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width={'80%'}>
                                <div className='key_f'>
                                    <div className='info_blk'>
                                        <h1 aria-details="key_h">GS1 Standard</h1>
                                        <p aria-label='key_p'>MEMSS is GS1 standard ready and enhances the efficiency, safety, and visibility of supply chains across physical and digital channels.</p>
                                        <div className='line_main'><span className='line_blk'><ArrowRightAlt className='line-icon' sx={{ color: '#3734A9' }} /></span></div>
                                    </div>
                                    <div className='img_blk'>
                                        <img src={three} height='120' />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width={'80%'}>
                                <div className='key_f'>
                                    <div className='info_blk'>
                                        <h1 aria-details="key_h">Cloud Based Solution</h1>
                                        <p aria-label='key_p'>MEMSS offers cloud-based standalone solutions or integrated solutions for clients. Systems can be hosted remotely / In-house</p>
                                        <div className='line_main'><span className='line_blk'><ArrowRightAlt className='line-icon' sx={{ color: '#3734A9' }} /></span></div>
                                    </div>
                                    <div className='img_blk'>
                                        <img src={four} height='120' />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item width={'80%'}>
                                <div className='key_f'>
                                    <div className='info_blk'>
                                        <h1 aria-details="key_h">Replicable Solution / Rapid Deployment</h1>
                                        <p aria-label='key_p'>MEMSS offers cloud-based standalone solutions or integrated solutions for clients. Systems can be hosted remotely / In-house</p>
                                        <div className='line_main'><span className='line_blk'><ArrowRightAlt className='line-icon' sx={{ color: '#3734A9' }} /></span></div>
                                    </div>
                                    <div className='img_blk'>
                                        <img src={five} height='100' />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box>
                <Container>
                    <Typography variant='h1' textAlign='center' fontWeight='900' fontSize='48px' mb={6}>Key  <Typography variant='h1' textAlign='center' display='inline' color='primary' fontWeight='900' fontSize='48px'>Modules</Typography></Typography>
                    <Box>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="primary" variant="scrollable"
                            scrollButtons="auto" centered indicatorColor="primary">
                            <Tab label="Dashboard" {...a11yProps(0)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Assets " {...a11yProps(1)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Work Order " {...a11yProps(2)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Equipment Library " {...a11yProps(3)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Parts " {...a11yProps(4)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Training " {...a11yProps(5)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Contracts " {...a11yProps(6)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Reports " {...a11yProps(7)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Manufacturer " {...a11yProps(8)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Third-Party " {...a11yProps(9)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Settings " {...a11yProps(9)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Cost" {...a11yProps(9)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Performance Indicators " {...a11yProps(9)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Maintenance Request Tools" {...a11yProps(9)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={4} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Dashboard" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Offers intuitive, real-time monitoring and maintenance." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Use the dashboard to visualise the complete maintenance status of your organisation with just one click." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Offers an overview of the total status of your complete assets, stock values, cost per asset etc." />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={8} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={imagePath}
                                            srcSet={imagePath}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Container>
                            <Grid container>

                                <Grid item xs={12} md={8} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={assets}
                                            srcSet={assets}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Assets" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Management solutions for all types of equipment both your client's and your own." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Offers a dynamic coding system as well as the option to oversee equipment via alternative codes." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Full data for any technical details , features, images and access to current as well as expired documents." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Operating history allows you to view running records and projections for any equipment." />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={4} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Work Order" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Maintenance plans and any work associated with equipment can be managed by calendar or operation projections. It is also possible to use a mix of both." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Offers a feature that lets you oversee full-team tasks. Includes monitoring and planning of labour-hours, parts approval, job orders, cost centre or equipment services and materials." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Downtime estimates and supervision of maintenance hours." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Visual representations of all scheduled operations via an Outlook-inspired calendar with a drag & drop features for orders." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Real-time comparisons of planned and actual costs. Offers improved control over all maintenance processes." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Intuitive invoice preparation and realisation of completed work." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Full maintenance analysis and planning setbacks." />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={8} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={work}
                                            srcSet={work}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Container>
                            <Grid container>

                                <Grid item xs={12} md={8} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={equipement}
                                            srcSet={equipement}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Equipement Library" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Search function enables fast navigation and monitoring via the equipment library." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Monitor equipment via its tracking number, serial number, model, asset type, category and more." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Assign loan status and monitor checkouts, return times or overdue items." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Intuitive equipment condition tracking." />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={4} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Parts" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Input and view full details of different parts." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Oversee location, transit status, order status, availability, manufacturer data etc." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Monitor re-order levels and quantities along with purchase or selling costs." />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={8} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={parts}
                                            srcSet={parts}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <Container>
                            <Grid container>

                                <Grid item xs={12} md={8} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={training}
                                            srcSet={training}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Training" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Manage users and their contact data, staff level etc." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Search by Ward Number or Location." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Graphical representations of daily training time per month." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="A comprehensive table offers an overview of current user training and all relevant data." />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={4} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Contracts" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Detailed dashboard offers a full overview of contracts." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Displays graphs for contract status and expiry date." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="View a table that outlines recent contracts for improved management." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Easy management of new contracts via contract type, duration, name, date, status etc." />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={8} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={contracts}
                                            srcSet={contracts}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                        <Container>
                            <Grid container>

                                <Grid item xs={12} md={8} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={reports}
                                            srcSet={reports}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Reports" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Quick access to work order status reports." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Search function provides name, code, category and description parameters to facilitate navigation." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Browse through a full Reports List, including category, report code, name, description boxes and more." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Analyse new, cancelled, allocated, closed or completed reports." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Use Title, Category, Priority Status, and other parameters to assess performance." />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={4} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Manufacturer" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Manufacturer dashboard provides graphs of models and assets." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="View equipment models, total models and assets by manufacturer." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Dashboards allow users to assess risk and safety classifications." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="User-friendly Model Search and Create functions." />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={8} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={manu}
                                            srcSet={manu}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={9}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={12} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Third-Party" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Manager tool offers graphs with detailed data on customers, suppliers, third parties." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Active, Closed, Do Not Contact and Inactive labels allow for quick analysis of all parties." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Detailed third-party registration of all relevant data, including contact information, status and location." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Categorise parties according to status (VIP, service provider, supplier and financial owner etc.)" />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={10}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={12} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Settings" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Fast access to all MEMSS settings via a comprehensive drop-down menu." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Set up and browse asset categories, classifications according to code, customise current status codes based on management needs." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Access detailed settings for asset locations and warehouse locations." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Tools for establishing electric safety classifications as well as risk classifications, company details and email configurations." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="View assets or modify financial status parameters, manage library assets and more." />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={11}>
                        <Container>
                            <Grid container>

                                
                                <Grid item xs={12} md={12} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Cost" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="A full Costs tool for creating documents related to all equipment types. Also includes maintenance work and systems." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Tools that allow management of accounting sheets for maintenance work." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Facilitated organisation of costs in regards to cost centres and accounts." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Simplifies and improves the integration of accounting and maintenance departments." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Real-time tracking for correct budget analysis." />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={12}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={12} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Performance Indicators" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Detailed performance monitoring through automatic, evolutionary, as well as comparative analysis. Applies to system, equipment, cost centre and additional variables." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Performance indicators for the current and past year, as well as the last five years." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Visual representations with pie charts and bar graphs." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Key Performance Indicator (KPI) calculation (organisational, economic, technical) with respect to international standards." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Tools provide creation and customisation options for unlimited indicators. Manual (recorded) and automatic (systems) are available." />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={value} index={13}>

                        <Grid container>

                            
                            <Grid item xs={12} md={12} >
                                <List sx={{ margin: '0px' }}>
                                    <ListItem disablePadding alignItems='flex-start'>
                                        <ListItemText primary="Maintenance Request Tools" primaryTypographyProps={{
                                            fontSize: 25,
                                            fontWeight: 'bold',
                                            letterSpacing: 0,
                                            color: 'primary'
                                        }} />
                                    </ListItem>
                                </List>
                                <List>
                                    <ListItem disablePadding alignItems='flex-start'>
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            <InboxIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Allows the user to create requests and input requested SLA response times." />
                                    </ListItem>
                                    <ListItem disablePadding alignItems='flex-start'>
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            <InboxIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Requests, as well as their status, can be monitored in real-time or automatically." />
                                    </ListItem>
                                    <ListItem disablePadding alignItems='flex-start'>
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            <InboxIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Create and track requests via mobile and desktop devices through a fully web-based workflow and interface." />
                                    </ListItem>
                                    <ListItem disablePadding alignItems='flex-start'>
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            <InboxIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="IOS & Android APP." />
                                    </ListItem>
                                    <ListItem disablePadding alignItems='flex-start'>
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            <InboxIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Satisfaction rating available after request conclusion." />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Container>
            </Box>
            <Box my={5}>
                <Container>
                    <Box>
                        <Tabs value={svalue} onChange={handleChanges} aria-label="basic tabs example" textColor="primary" variant="scrollable"
                            scrollButtons="auto" centered indicatorColor="primary">
                            <Tab label="Product Management" {...a11yProps(0)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Supplier Management " {...a11yProps(1)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Inventory Management" {...a11yProps(2)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Quotation order" {...a11yProps(3)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Sales order" {...a11yProps(4)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Purchase order" {...a11yProps(5)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Invoice Management " {...a11yProps(6)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Finance-integrated solutions" {...a11yProps(7)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Asset Management " {...a11yProps(8)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                            <Tab label="Maintenance Notification " {...a11yProps(9)} sx={{ fontWeight: '900', textTransform: 'capitalize', fontSize: '15px' }} />
                        </Tabs>
                    </Box>
                    <TabPanel value={svalue} index={0}>
                        <Container>
                            <Grid container>

                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={ProductManagement}
                                            srcSet={ProductManagement}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Product Management " primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Unlock the fullest potential of your assets with Product Management! Streamline preventive, predictive and corrective maintenance tasks to maximize utilization and optimize production levels. " />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Increased access authorizations ensure secure operations while error-free alerts detect irregularities quickly - all so you can protect & extend the life of your products for a strong head start in success." />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={svalue} index={1}>
                        <Container>
                            <Grid container>

                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={supplymanagement}
                                            srcSet={supplymanagement}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Supplier Management " primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Supplier Management can keep you up-to-date on your job progress. With paper-based processes, you need to be constantly on the lookout for human error, slow process execution, security risks, storage issues, and more. It provides a comprehensive suite of solutions for tracking the lifecycle of any medical device - from notification to work order processing and completion." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Manage your supplier management processes across your supply chain, from initial qualification and selection through ongoing monitoring and performance measurement. All at real-time speeds!" />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={svalue} index={2}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Inventory Management" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Keeping all your assets and equipment operational is a critical part of successful inventory management. You can ensure your assets and equipment are always in check." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="But how can you guarantee that needed parts, materials, and maintenance techs are always in the right place at the right time? MEMSS makes sure no stone is left unturned so you never fall behind on repairs or replacements - leaving nothing to chance! Keep track of every item in real-time, monitor stock levels, and eliminate outdated inventory — all while gaining full control over the entire process.Achieve complete control over your inventory with a range of features that make monitoring easy!" />
                                        </ListItem>

                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={InventoryManagement}
                                            srcSet={InventoryManagement}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={svalue} index={3}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={Quotationorder}
                                            srcSet={Quotationorder}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Quotation order" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary=" A well-organized quotation order system is essential for a business. Being able to quickly access
important data related to quotes, orders, and pricing helps expedite the sales process and
promote efficiency." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Having an easily navigable system allows sales teams to look up product
information and pricing quickly and can also help reduce administrative errors.  " />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary=" With the right
quotation order software, sales personnel can streamline their daily routines and ensure that all
business operations are conducted in a professional, organized manner that facilitates seamless
customer experiences." />
                                        </ListItem>
                                    </List>
                                </Grid>

                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={svalue} index={4}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Sales order" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary=" Creating sales orders within the system allows for smooth communication throughout the
business process with accurate details on the agreement, payment methods, and quantity of
products/services." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary=" You can comfortably confirm the terms and prices and verifies the appropriate
stock availability and ability to deliver the goods or services requested on time. " />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Hence, a sales
order serves both internally to keep track of all completed orders and externally if there is any
need to recall the details of a particular transaction. " />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary=" Many internal order processes and inventory
management processes can be effectively streamlined and rectified using sales orders." />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={Salesorder}
                                            srcSet={Salesorder}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={svalue} index={5}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={Purchaseorder}
                                            srcSet={Purchaseorder}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Purchase order" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Streamline the process of acquiring goods and services, and create an up-to-date spending
culture that can contribute to improved business performance and higher profit margins." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="The
specifics of what is being purchased are included in the PO: a description of the order, how many
items are requested, and the agreed-upon price and payment terms. " />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Using this PO number
system makes it easy to track your purchases and spending more efficiently by attributing each
P a g e | 14
PO sent with its unique number." />
                                        </ListItem>
                                    </List>
                                </Grid>

                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={svalue} index={6}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Invoice Management" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Keep your invoicing organized and on budget with tools like QuickBooks or Xero to quickly monitor resource, labour, and materials costs for each project." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary=" Identify issues before they get too costly! Our system tracks every component involved in a project, allowing you to ascertain which items use up your labour and materials costs." />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={InvoiceManagement}
                                            srcSet={InvoiceManagement}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={svalue} index={7}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Finance-integrated solutions" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Manage your finances with ease! Our integrated solutions offer everything from quotes to sales orders and purchasing data, so you never miss a beat regarding money management." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Forget the hassle of tracking down those pesky paperwork receipts and manage your customer, contract, and financial data (Quotations, Purchase Orders, Sales Orders, Invoices and connect to Quick Books or Xero) confidently - let us help get your financial life for good." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Our integrated financial architecture consists with" />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary=" Audit & Traceability
" />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary=" Planning, Budgeting & Forecasting" />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Quotations/ Purchasing orders/ Invoices
 " />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Workforce capacity
 " />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Management & Reporting " />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Inventory management " />
                                        </ListItem>

                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={Financeintegrated}
                                            srcSet={Financeintegrated}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={svalue} index={8}>
                        <Container>
                            <Grid container>

                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={AssetManagement}
                                            srcSet={AssetManagement}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Asset Management" primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Easy-To-Use, All-In-One Solution For Tracking And Managing Your Assets." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Keep track of Asset manufacturer, model, serial number and type" />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Associate documents with your assets and more." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Gain insights into Asset Health and Criticality" />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Locate the Asset with the stored Location details" />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Monitor Asset Reliability and Validity" />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value={svalue} index={9}>
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={6} >
                                    <List sx={{ margin: '0px' }}>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemText primary="Maintenance Notification " primaryTypographyProps={{
                                                fontSize: 25,
                                                fontWeight: 'bold',
                                                letterSpacing: 0,
                                                color: 'primary'
                                            }} />
                                        </ListItem>
                                    </List>
                                    <List>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Going The Extra Mile For Your Business Can Be As Simple As MEMSS's Maintenance Notifications." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="The maintenance notifications in MEMSS allow you to process any information or problems that may arise in your medical equipment. Avoid failures by allowing your professionals to record and process data ranging from simple enquiries to complex, specialized problems." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Perform various operations in the system." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Keep track of the asset details." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Break down details and actions taken." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Break down details and actions taken." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Assign the task and coordinate with the department affected by the problem." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Describe errors or exceptions based on the notification type." />
                                        </ListItem>
                                        <ListItem disablePadding alignItems='flex-start'>
                                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Record tasks and work list." />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} px={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <ImageListItem sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '4px 4px 16px 6px rgba(55, 52, 169, 0.12)',
                                        borderRadius: '14px',
                                        padding: '10px',
                                    }}>
                                        <img
                                            src={notificationMaintenance}
                                            srcSet={notificationMaintenance}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>

                </Container>
            </Box>

        </Box>
    )
}
