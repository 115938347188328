import { createStyles, makeStyles } from '@mui/styles';

const navStyle = makeStyles(() =>
  createStyles({
      bodyContainer: {
        display: 'flex',
      },
    //   background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) center center / cover no-repeat, url(/assets/background/overlay_2.jpg);
    // background-repeat: no-repeat;
    // background-position: center center;
  })
);

export { navStyle };
