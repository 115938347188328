import { createTheme } from "@mui/material";
const theme = createTheme({
    typography: {
        fontFamily: ["Manrope", 'sans-serif']
            .join(","),
    },
    palette: {
        mode: 'light',
        primary: {
            main: "#5D5FEF",
        },
        error: {
            main: "#F57059",
        },
        warning:{
            main: "#FEBC1F",
        },
        success:{
            main: "#44997C",
        }
        
    }
});
export default theme;