import { homeStyle } from './Home_style';
import * as React from 'react';
import { Box, Button, Container, Grid, Paper, TextField, Typography, Card, CardContent, IconButton, Icon, Rating, } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { commonStyle } from './common_style';
import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export default function Home(props: any) {
    const properties = {
        autoplay: false,
        infinite: false,
        indicators: (i) => <div className="indicator"></div>
    };
    const home = homeStyle();
    const classes = commonStyle();
    const words = [
        "Hospitals",
        "Service Providers",
        "Suppliers"
    ];
    const [index, setIndex] = useState(0);
    const [onePageScrollTargetClass, setOnePageScrollTargetClass] = useState<string>('');
    const [testMonials, setTestMonials] = useState<any>([{
        id: new Date().getTime(),
        imageUrl: `https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
        rating: 5,
        review: `With its intuitive interface and easy-to-use features, we are now able to track our inventory, manage our Reports, and Generate contracts`,
        reviewer: 'Igor Kup',
        designation: 'Developer'
    }, {
        id: new Date().getTime(),
        imageUrl: `https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
        rating: 4,
        review: `We’ve been using Memms for the past six months. With its intuitive interface and easy-to-use features, we are now able to track our inventory, manage our Reports, and Generate contracts`,
        reviewer: 'Ninam',
        designation: 'Manager'
    }, {
        id: new Date().getTime(),
        imageUrl: `https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`,
        rating: 3,
        review: `We’ve been using Memms for the past six months, and it has transformed the way we manage our operations`,
        reviewer: 'John Doe',
        designation: 'Designer'
    }]);

    const [currentTestMonial, setCurrentTestMonial] = useState<any>(testMonials[0]);

    useEffect(() => {
        const intervalDelayMilliseconds = 1000;
        const interval = setInterval(() => {
            setIndex((prevIndex) => {
                return prevIndex + 1 < words.length ? prevIndex + 1 : 0;
            });
        }, intervalDelayMilliseconds);
        window.addEventListener('scroll', handleScroll, { passive: true});
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(interval);
        }
    });

    const handleScroll = () => {
        const onePageScrollTarget: any = document.querySelector('.one-page-scroll-tabs');
        const calculatedScrollPos = (onePageScrollTarget?.offsetTop - (window.scrollY - 85));
        if(calculatedScrollPos <= 0) {
            setOnePageScrollTargetClass('position-sticky');
        } else {
            setOnePageScrollTargetClass('');
        }
    };

    return (
        <Box>
            <Box position={'relative'} className={home.landingbanner} >
                <Icon sx={{ position: 'absolute', display: 'flex', top: '-20px', left: 0, fontSize: '24vmin' }} color='primary' ><span className="memss_c-Vector" style={{ color: 'rgb(163 163 249)', zIndex: 3, }}></span></Icon>
                <Box position={'absolute'} bottom={0} left={0} right={0} sx={{ top: '-150px', backgroundColor: '#ffffffeb', backgroundImage: 'url(https://zone-ui.vercel.app/assets/background/overlay_2.jpg)', backgroundOrigin: 'center', backgroundSize: 'cover', backgroundBlendMode: 'overlay' }}></Box>
                <Container sx={{ zIndex: 2, position: 'relative' }}>
                    <Box px={{ xs: '2', md: '12' }} mx={{ xs: '2', md: '5' }}>
                        <Typography variant="h1" my={'60px'} textAlign={'center'} fontWeight={'800'} fontSize={{ xs: '20px', sm: '35px', md: '48px' }} mx={{ xs: '10px', md: '70px' }} px={{ xs: '10px', md: '60px' }}>MEMSS empowers global asset management from a single point for
                            <Typography className={'text-animated'} position='relative' variant="h1" display={'inline'} textAlign={'center'} fontWeight={'bolder'} fontSize={{ xs: '20px', sm: '35px', md: '48px' }} color="primary" key={words[index]}>
                                &nbsp;{words[index]}
                            </Typography>
                        </Typography>
                    </Box>
                    <Grid className={`one-page-scroll-tabs ${onePageScrollTargetClass}`} container justifyContent={'center'} display={'flex'} spacing={3} pb={10}>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cst_block} color='primary'>
                                <Button fullWidth color='primary' sx={{ fontWeight: '900', padding: '20px' }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                        <Icon className="memss_c-Vector-6" fontSize='large' sx={{ margin: '0px 20px' }} color='primary'></Icon>
                                        <CardContent sx={{ p: '0px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                                 Service
                                            </Typography>
                                            <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                                Providers
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Button>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cst_block} color='warning'>
                                <Button fullWidth color='warning' sx={{ fontWeight: '900', padding: '20px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                        <Icon className="memss_c-Union" fontSize='large' sx={{ margin: '0px 20px' }} color='warning'></Icon>
                                        <CardContent sx={{ p: '0px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                                MVS
                                            </Typography>
                                            <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                                Providers
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Button>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cst_block} color='error'>
                                <Button fullWidth color='error' sx={{ fontWeight: '900', padding: '20px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                        <Icon className="memss_c-Union-1" fontSize='large' sx={{ margin: '0px 20px' }} color='error'></Icon>
                                        <CardContent sx={{ p: '0px !important', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                                
                                            </Typography>
                                            <Typography gutterBottom variant="h5" component="div" m='0px' fontSize={18} fontWeight='bold'>
                                                Suppliers
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Button>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
                <Icon sx={{ position: 'absolute', display: 'flex', bottom: '20vmin', right: 0, fontSize: '24vmin' }} color='primary' ><span className="memss_c-Vector" style={{ color: 'rgb(163 163 249)', zIndex: 3, }}></span></Icon>
            </Box>
            <Box className={home.service_1} position='relative'>
                <Box className={home.overlay}></Box>
                <Container>
                    <Grid container justifyContent={'center'} display={'flex'} zIndex={2} position='relative' py={14}>
                        <Grid item xs={12} md={7}>
                            <Typography variant='h1' color={'white'} fontWeight={'800'} className={home.info_text}>1</Typography>
                            <Typography variant='h3' component="h2" color={'white'} fontWeight={'800'} className={home.info_text}> </Typography>
                            <Typography variant='h3' component="h2" color={'white'} fontWeight={'800'} className={home.info_text}>Service Providers</Typography>
                        </Grid>
                        <Grid item xs={12} md={5} display='flex' alignItems='center' justifyContent='center' borderRadius='20px'>
                            <Paper sx={{ maxWidth: '400px', borderRadius: '20px' }}>
                                <List sx={{ bgcolor: 'background.paper', px: 3, borderRadius: '20px' }}>
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center' }}
                                            primary="Asset Lifecycle Management (Commission - Decommissioning - Recycling)"
                                            primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'primary',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center' }}
                                            primary=" KPI"
                                            primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'primary',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center' }}
                                            primary="GS1 UK Asset Tracking" primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'primary',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary=" Workflow Automation
                                            (Reactive, PM and Info)"  primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'primary',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary=" Parts Traceability." primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'primary',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary=" Contract and Finance Management" primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'primary',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Equipment Library &  Loaned Asset Tracking" primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'primary',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={home.service_2} position='relative'>
                <Box className={home.overlay}></Box>
                <Container>
                    <Grid container justifyContent={'center'} display={'flex'} zIndex={2} position='relative' py={14}>
                        <Grid item xs={12} md={5}>
                            <Paper sx={{ maxWidth: '400px', borderRadius: '20px' }}>
                                <List sx={{ bgcolor: 'background.paper', px: 3, borderRadius: '20px' }}>
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center' }}
                                            primary=" Suppliers and Customer Management."
                                            primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: '#CC9614',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center' }}
                                            primary="Centralized Engineers time Management" primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: '#CC9614',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Asset Contract Lifecycle Management
                                            (Commissiong to Decommision)"  primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: '#CC9614',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Quote to Invoice Automation
                                            (Quotes, Sales orders, Puchase Orders, Invoice)" primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: '#CC9614',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Loaned Asset Tracking " primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: '#CC9614',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Accounts integration - 
                                            Quick books, Xero and Sage" primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: '#CC9614',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Parts Traceability and  Parts Stock Management." primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: '#CC9614',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={7} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                            <Typography variant='h1' color={'white'} fontWeight={'800'} sx={{}} className={home.info_text}>2</Typography>
                            <Typography variant='h3' component="h2" color={'white'} fontWeight={'800'} className={home.info_text}>Multi Vendor</Typography>
                            <Typography variant='h3' component="h2" color={'white'} fontWeight={'800'} className={home.info_text}>
                                Suppliers </Typography>
                        </Grid>

                    </Grid>
                </Container>
            </Box>
            <Box className={home.service_3} position='relative' mb={9}>
                <Box className={home.overlay}></Box>
                <Container>
                    <Grid container justifyContent={'center'} display={'flex'} zIndex={2} position='relative' py={14}>
                        <Grid item xs={12} md={7}>
                            <Typography variant='h1' color={'white'} fontWeight={'800'} className={home.info_text}>3</Typography>
                            <Typography variant='h3' component="h2" color={'white'} fontWeight={'800'} className={home.info_text}>
                                
                            </Typography>
                            <Typography variant='h3' component="h2" color={'white'} fontWeight={'800'} className={home.info_text}>Suppliers </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Paper sx={{ maxWidth: '400px', borderRadius: '20px' }}>
                                <List sx={{ bgcolor: 'background.paper', px: 3, borderRadius: '20px' }}>
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center' }}
                                            primary="Customer and Suppliers Management."
                                            primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'error',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center' }}
                                            primary=" Product Management " primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'error',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary=" Customized pricing
                                            Contract price, Customer price, Volume pricing"  primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'error',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Inventory Management." primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'error',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Quote Management both Repair and New Sales" primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'error',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Sales Orders and Shipment tracking " primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'error',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Purchase Order Management" primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'error',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem alignItems="flex-start" sx={{ py: 2 }}>
                                        <ListItemText sx={{ textAlign: 'center', color: 'primary' }}
                                            primary="Invoices Management" primaryTypographyProps={{
                                                fontSize: '1rem',
                                                color: 'error',
                                                fontWeight: 'medium',
                                                letterSpacing: 0,
                                            }} />
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box mb={14}>
                <Container>
                    <Typography mb={7} textAlign={'center'} variant='h3' fontWeight={'bold'}>We <Typography variant='h3' color={'primary'} fontWeight={'bold'} display={'inline'}>Help</Typography> your Organization</Typography>
                    <Grid container justifyContent={'center'} display={'flex'} spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cst_block} color='primary'>
                                <Box color='primary' sx={{ fontWeight: '900', padding: '20px' }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', width: '100%' }}>
                                        <span className="memss_c-Vector-1" style={{ fontSize: '40px', marginBottom: '15px' }}></span>
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', p: '0px !important' }}>
                                            <Typography gutterBottom variant="h2" fontSize={19} fontWeight='bold' color={'primary'}>
                                                Reduce Risk
                                            </Typography>
                                            <Typography gutterBottom variant="body1" component="div" m='0px' fontSize={14} color={'primary'}>
                                                Of £18.6 billion clinical negligence claims in 2019, £1.3 billion are due to poor quality maintenance service of medical devices
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cst_block} color='warning'>
                                <Box color='warning' sx={{ fontWeight: '900', padding: '20px' }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', width: '100%' }}>
                                        <span className="memss_c-correct" style={{ fontSize: '40px', marginBottom: '15px' }}></span>
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', p: '0px !important' }}>
                                            <Typography gutterBottom variant="h2" fontSize={19} fontWeight='bold' color={'#FEBC1F'}>
                                                Improve Quality
                                            </Typography>
                                            <Typography gutterBottom variant="body1" component="div" m='0px' fontSize={14} color={'#FEBC1F'}>
                                                CQC estimates, 23% hospitals rated “Require improvement” in 2019 are due to poor compliance of medical equipment
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card className={classes.cst_block} color='error'>
                                <Box color='error' sx={{ fontWeight: '900', padding: '20px' }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', width: '100%' }}>
                                        <span className="memss_c-efficiency-1" style={{ fontSize: '40px', marginBottom: '15px' }}></span>
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', p: '0px !important' }}>
                                            <Typography gutterBottom variant="h2" fontSize={19} fontWeight='bold' color={'error'}>
                                                Increase Efficiency
                                            </Typography>
                                            <Typography gutterBottom variant="body1" component="div" m='0px' fontSize={14} color={'error'}>
                                                Poor capital replacement plan & poor monitoring of service level agreements are estimated to cost around £1.6m more per 1000 beds per year
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box mb={9} display={'none'}>
                <Container>
                    <Typography textAlign={'center'} variant='h3' fontWeight={'bold'}>Check what our <Typography variant='h3' color={'primary'} fontWeight={'bold'} display={'inline'}>clients </Typography>are <Typography variant='h3' color={'primary'} fontWeight={'bold'} display={'inline'}>saying</Typography></Typography>
                    <Grid container justifyContent={'center'} display={'flex'} m={'20px 0px'}  my={10} mb={20}>
                        <Grid item xs={12} md={6} display='flex' alignItems= 'center' justifyContent='center'>
                            <Card sx={{width: '350px', height: 'max-content',position:'relative',  overflow:'visible'}} elevation={0}>
                                <Slide onStartChange={(from: number, to: number) => {
                                    setCurrentTestMonial(testMonials[to]);
                                }} {...properties}>
                                    {testMonials?.map(single => {
                                        return <img src={single?.imageUrl} style={{maxWidth:'100%', maxHeight: '100%', zIndex:99}}/>
                                    })}
                                </Slide>
                                <Icon sx={{ position: 'absolute', display: 'flex',zIndex:-1, top: '10px', right: '-23px', fontSize: '14vmin', }} color='primary' ><span className="memss_c-Vector" style={{ color: 'rgb(163 163 249)', zIndex: 3, }}></span></Icon>
                                <Icon sx={{ position: 'absolute', display: 'flex',zIndex:0, top: '-11px', left: '-43px', fontSize: '10vmin' }} color='primary' ><span className="memss_c-Group-269"><span className="path1"></span><span className="path2"></span></span></Icon>
                                <Icon sx={{ position: 'absolute', display: 'flex',zIndex:0, bottom: '20px', right: '-20px', fontSize: '5vmin' }} color='primary' ><span className="memss_c-Ellipse-56" style={{ color: 'rgb(163 163 249)', zIndex: 3, }}></span></Icon>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='h1' color={'dark'} fontWeight={'bold'} fontSize={'18px'} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M9.5 1.15794L9.27287 2.79042C8.81861 2.75223 8.44953 2.8286 8.16562 3.01954C7.8817 3.20092 7.68297 3.46823 7.5694 3.82145C7.4653 4.16513 7.44637 4.56609 7.51262 5.02433H9.5V8.91936H5.6388V5.02433C5.6388 3.64961 5.95584 2.60903 6.58991 1.90258C7.22397 1.18658 8.19401 0.938371 9.5 1.15794ZM4.3612 1.15794L4.13407 2.79042C3.67981 2.75223 3.31073 2.8286 3.02681 3.01954C2.7429 3.20092 2.54416 3.46823 2.4306 3.82145C2.3265 4.16513 2.30757 4.56609 2.37382 5.02433H4.3612V8.91936H0.5V5.02433C0.5 3.64961 0.817035 2.60903 1.4511 1.90258C2.08517 1.18658 3.0552 0.938371 4.3612 1.15794Z" fill="black" stroke-width="6"></path></svg>
                            </Typography>
                            <Rating name="read-only" value={currentTestMonial?.rating} readOnly sx={{ display: 'flex', mb: '10px' }} />
                            <Typography variant='body2' color={'primary'} fontWeight={'bold'} fontSize={'18px'} sx={{ marginBottom: '20px' }}>
                                {currentTestMonial?.review}
                            </Typography>
                            <Typography variant='body2' color={'dark'} fontWeight={'bold'} fontSize={'14px'}>{currentTestMonial?.reviewer}</Typography>
                            <Typography variant='body2' color={'#AEAEAE'} fontSize={'12px'}>{currentTestMonial?.designation}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box >
    )
}   