
import { footerStyle } from './Footer_style';
import { commonStyle } from './common_style';
import { showScheduleDemoModal } from '../utils';
import { Box, Typography, Paper, Grid, TextField, Button, Container } from '@mui/material';
export default function Footer() {
    const footer = footerStyle();
    const classes = commonStyle();
    return (
        <Box className={footer.footerblk}>
            <Box position={'relative'} sx={{ backgroundColor: { xs: '#3734a9', md: '#3734a9' } }} textAlign="center">
                <Container sx={{paddingBottom:'100px'}}>
                    <Box position={'absolute'} left={'0'} right={'0'} width={'100%'} top={'-13vmin'} className={footer.footerbg} display='none' ></Box>
                    <Typography variant='body1' color={'white'} padding={'20px'}>
                        MEMSS
                    </Typography>
                    <Typography variant='h3' color={'white'} fontWeight={'bold'} fontSize={'30px'}>
                        Schedule a Free Demo today
                    </Typography>
                    <Grid container spacing={2} pt={'30px'} alignItems="center" justifyContent={'center'} display={'flex'} >
                        <Grid item xs={'auto'} alignItems='center' display={'flex'} pt={'0px'}>
                            <Button onClick={() => {
                            showScheduleDemoModal(true);
                        }} variant="contained" sx={{ textTransform: 'Capitalize', fontWeight: 'bold', }}>Schedule Demo</Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} pt={'30px'} alignItems="start" justifyContent={'center'} display={'flex'} margin={'0px auto'}>
                        <Grid item xs={12} md={3} textAlign={'start'} >
                            <Typography variant='body1' color={'#9b99d4'} marginBottom={'15px'}>
                                Address
                            </Typography>
                            <Typography variant='body1' color={'white'} fontSize={'14px'} fontWeight={'normal'}>
                            WTDATATECH LTD ,Suite 105-2 Fairbourne Drive, Atterbury, Milton Keynes, England, MK10 9RG
                            </Typography>
                        </Grid>
                        <Grid item xs={'auto'} md={1} textAlign={'start'}></Grid>
                        <Grid item xs={12} md={3} textAlign={'start'}>
                            <Typography variant='body1' color={'#9b99d4'} marginBottom={'15px'}>
                                Contact Us
                            </Typography>
                            <Typography variant='body1' color={'white'} fontSize={'14px'} fontWeight={'normal'} marginBottom={'10px'}>
                                cs@memss.co.uk
                            </Typography>
                            <Typography variant='body1' color={'white'} fontSize={'14px'} fontWeight={'normal'}>
                                (+44)1908 803 408
                            </Typography>
                        </Grid>
                    </Grid>

                </Container>
            </Box>
        </Box>
    )
}
