import { createStyles, makeStyles } from '@mui/styles';
import service_1 from './../assets/images/first.png';
import service_2 from './../assets/images/second.png';
import service_3 from './../assets/images/third.png';
import service_4 from './../assets/images/pexels.png';
const homeStyle = makeStyles(() =>
  createStyles({
    landingbanner: {
      position: 'relative',
      '&::before': {
        content: "'/e90f'",
        fontFamily: 'memss_corp',
        speak: 'never',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: 1,
      },
      '&::after': {
        content: "'\e90f'",
      }
    },
    service_1: {
      backgroundImage: `url(${service_1})`,
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '& $overlay': {
        background: 'linear-gradient(269.9deg, #2723B0 -13.18%, rgba(178, 176, 255, 0.17) 93.56%, rgba(178, 176, 255, 0.17) 93.56%)',
        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
      },

    },
    service_2: {
      backgroundImage: `url(${service_2})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '& $overlay': {
        background: 'linear-gradient(106.16deg, #FEBC1F 3.52%, #FEBC1F 3.53%, rgba(251, 202, 76, 0.86) 14.14%, rgba(235, 235, 235, 0.54) 68.61%, #FFFFFF 90.61%)',
        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        '&::before': {
          content: "''",
          backgroundColor: 'red',
        }
      },

    },
    service_3: {
      backgroundImage: `url(${service_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '& $overlay': {
        background: 'linear-gradient(90.05deg, rgba(235, 133, 125, 0.63) 0.05%, #EB857E 0.06%, rgba(236, 134, 126, 0.67) 28%, rgba(255, 202, 198, 0.54) 74.73%, #FFE2E0 99.96%)',
      },

    },
    service_4: {
      backgroundImage: `url(${service_4})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition:'bottom',
      '& $overlay': {
        background: 'linear-gradient(90.36deg, #3734A9 8.43%, rgba(178, 176, 255, 0.17) 69%, rgba(157, 154, 255, 0.17) 69%)',
        filter: 'blur(61.5px)',
        '&::before': {
          content: "''",
          backgroundColor: 'red',
        }
      },

    },
    overlay: {
      position: 'absolute',
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
    info_text: {
      textShadow: '0px 0px 7px rgba(0, 0, 0, 0.36)',
    }
    //   background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) center center / cover no-repeat, url(/assets/background/overlay_2.jpg);
    // background-repeat: no-repeat;
    // background-position: center center;
  })
);

export { homeStyle };
