import { createStyles, makeStyles } from '@mui/styles';

const commonStyle = makeStyles(() =>
  createStyles({
    bodyContainer: {
      display: 'flex',
    },
    default_input: {
      '& .MuiFormLabel-root': {
        fontWeight: 'bold',
      },
      '& .MuiInputBase-root': {
        fontWeight: 'bold',
        border: 'none',
        background: 'rgba(93, 95, 239, 0.1)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(238 239 253)',
      }
    },
    cst_block: {
     
      boxShadow: '4px 4px 0px #5D5FEF',
      borderRadius: '20px !important',
      '&[color="primary"]':{
        border: '2px solid #5D5FEF !important',
        background: '#EBEBF6',
        boxShadow: '10px 10px #5D5FEF',
      },
      '&[color="warning"]':{
        border: '2px solid #FEBC1F !important',
        background: '#FFF8E6',
        boxShadow: '10px 10px  #FEBC1F',
      },
      '&[color="error"]':{
        border: '2px solid #F57059 !important',
        background: '#FDDED9',
        boxShadow: '10px 10px  #F57059',
      },
      '&[color="Success"]':{
        border: '2px solid #91CCB8 !important',
        background: '#E2F7F0',
        boxShadow: '10px 10px  #91CCB8',
      }
    }
  })
);

export { commonStyle };