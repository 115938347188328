import { createStyles, makeStyles } from '@mui/styles';
import footer from './../assets/images/footer.png';
const footerStyle = makeStyles(() =>
  createStyles({
    footerbg: {
      backgroundImage: `url(${footer})`,
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundSize:'cover',
      height: '13vmin',
    },
    footerblk:{
        marginTop: 'calc(13vmin)',
    }
    //   background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)) center center / cover no-repeat, url(/assets/background/overlay_2.jpg);
    // background-repeat: no-repeat;
    // background-position: center center;
  })
);

export { footerStyle };
