import { ThemeProvider,Box } from "@mui/material";
import { Route, Routes } from "react-router";
import AboutUs from "./components/About";
import Home from "./components/Home";
import ResponsiveAppBar from "./components/Navbar";
import PageNotFound from "./components/PageNotFound";
import Price from "./components/Price";
import Product from "./components/Product";
import Solutions from "./components/Solution";
import theme from "./assets/styles/default";
import Footer from "./components/Footer";
import ScheduleDemo from "./components/ScheduleDemo";

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ResponsiveAppBar />
        <ScheduleDemo />
        <Box>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="Home" element={<Home />} />
            <Route path="Product & Services" element={<Solutions />} />
            {/* <Route path="Product & Services" element={<Product/>}/> */}
            <Route path="Price" element={<Price />} />
            <Route path="AboutUs" element={<AboutUs />} />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </Box>
        <Footer/>
      </ThemeProvider>
    </div>
  );
}
export default App;
